import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LangModule } from '@seekda/angular-i18n';
import { BmComponentsModule } from '@seekda/bm-components';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { UsersService } from './core/services/users/users.service';
import { ErrorModule } from './pages/global/error/error.module';
import { HotelDashboardModule } from './pages/master/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
    declarations: [
        AppComponent,
    ],

    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        CoreModule,
        AppRoutingModule,
        SharedModule,
        HotelDashboardModule,
        BmComponentsModule,
        LangModule,
        ErrorModule,
    ],
    providers: [
        UsersService,
        provideHttpClient(withInterceptorsFromDi()),
    ],
    bootstrap: [ AppComponent ],
})
export class AppModule { }
