import { Component, OnDestroy } from '@angular/core';
import { ActivationEnd, Event, EventType, Router } from '@angular/router';
import { TrackingService } from '@bmng/services/tracking/tracking.service';
import { Subscription, filter } from 'rxjs';

import { ContextService } from '@bmng/services/context/context.service';
import { NavigationResult } from './core/services/navigation/routing-navigator.interface';
import { StackableRoutingNavigatorService } from './core/services/navigation/stackable-routing-navigator.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ],
    standalone: false,
})
export class AppComponent implements OnDestroy {
    private subscription: Subscription =  new Subscription();

    activeRouterLevel: number = 0;

    hotelId: string;

    constructor(
        private navService: StackableRoutingNavigatorService,
        private router: Router,
        private trackingService: TrackingService,
    ) {
        this.subscription.add(
            this.router.events.pipe(
                filter((event: Event) => event.type === EventType.ActivationEnd),
                filter((event: ActivationEnd) => !event.snapshot.routeConfig.children?.length),
            ).subscribe((event: ActivationEnd) => {
                const contextParams = ContextService.getContextParams(event.snapshot);
                const isHotel = contextParams.type === 'hotel';

                if (isHotel) {
                    this.hotelId = ContextService.getContextParams(event.snapshot).id;
                } else {
                    this.hotelId = null;
                }
            }),
        );

        this.subscription.add(
            this.navService.getActiveStackLevel().subscribe((result: NavigationResult) => {
                this.activeRouterLevel = result[0];
            }),
        );

        this.subscription.add(
            this.router.events.subscribe((event: Event) => {
                this.trackingService.trackNavigationEvent(event);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
