import { Injectable } from '@angular/core';
import { SessionStorageService } from '@seekda/bm-components';

import { RatePlanSetup } from './../rates/interfaces/rate-plan-setup.interface';

@Injectable()
export class DerivedRateRulesPersistanceService {
    public static readonly DERIVED_RATE_OBJECT_ID = 'derivedRateInformation';

    constructor(
        private sessionSrv: SessionStorageService,
    ) {}

    store(derivedRateRules: RatePlanSetup[]): void {
        this.sessionSrv.setItem(
            DerivedRateRulesPersistanceService.DERIVED_RATE_OBJECT_ID,
            derivedRateRules,
        );
    }

    clear(): void {
        this.sessionSrv.removeItem(DerivedRateRulesPersistanceService.DERIVED_RATE_OBJECT_ID);
    }

    get(): RatePlanSetup[] {
        const items = this.sessionSrv.getItem<RatePlanSetup[]>(
            DerivedRateRulesPersistanceService.DERIVED_RATE_OBJECT_ID,
        );

        if (!!items && items.length > 0) {
            return items;
        }

        return null;
    }
}
