import { Injectable } from '@angular/core';
import { MomentHelpers } from '@seekda/bm-components';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SubscriptionReportPayload } from '../../../pages/generic/reports/common/types/report-type.type';
import { SubscriptionReportDetails } from '../reports/interfaces/reports-subscription.interface';
import { ReportsService } from '../reports/reports.service';

import {
    BookingSummaryGroupingColumn,
    BookingSummaryParams,
    BookingSummaryServiceInterface,
} from './interfaces/booking-summary-service.interface';
import { BookingSummary } from './interfaces/booking-summary.interface';

@Injectable({
    providedIn: 'root',
})
export class BookingDataService implements BookingSummaryServiceInterface {
    constructor(
        private reportsService: ReportsService,
    ) { }

    getBookingSummary(params: BookingSummaryParams, type: BookingSummaryGroupingColumn): Observable<BookingSummary[]> {
        return this.reportsService.getOnDemandReport(
            params.propertyCode,
            'hotel',
            this.getReportPayloadFrom(params, type),
        ).pipe(
            map(report => {
                const results = report.result || [];

                return results.map((pkgResult: SubscriptionReportDetails) => {
                    let code = '';

                    switch (type) {
                        case 'RATE_CODES':
                            code = pkgResult.rateCodes;
                            break;

                        case 'PACKAGE_CODES':
                            code = pkgResult.packageCodes;
                            break;

                        case 'SERVICE_CODES':
                            code = pkgResult.serviceCodes;
                            break;
                        default:
                    }

                    return {
                        code,
                        revenue: pkgResult.total,
                        bookings: pkgResult.bookings,
                        type,
                    };
                });
            }),
        );
    }

    private getReportPayloadFrom(params: BookingSummaryParams, type: BookingSummaryGroupingColumn): SubscriptionReportPayload {
        const payload: SubscriptionReportPayload = {
            subscription: {
                id: null,
                enabled: false,
                name: null,
                recurrence: null,
                dataWindow: null,
                dataWindowStart: null,
                dataWindowEnd: null,
                firstDayOfWeek: null,
                type: 'RESERVATION',
                variant: 'CUSTOM',
                recipients: null,
                createdBy: null,
                modifiedBy: null,
                created: null,
                modified: null,
                scheduledDateTime: null,
                languageCode: null,
                custom: {
                    grouping: {
                        primary: type,
                        secondary: null,
                        tertiary: null,
                    },
                    columns: [
                        {
                            id: type,
                            enabled: true,
                        },
                        {
                            id: 'BOOKINGS',
                            enabled: true,
                        },
                        {
                            id: 'TOTAL',
                            enabled: true,
                        },
                    ],
                    filters: !!params.filters ? params.filters : {},
                    kpis: null,
                    summary: null,
                },
                timezone: MomentHelpers.getTimezone(),
            },
            size: null,
            start: null,
        };

        if (!!params.currencyCode) {
            payload.subscription.currency = params.currencyCode;
        }

        if (!params.startDate) {
            payload.subscription.dataWindow = 'YEAR_UP_TO_DATE';
        } else {
            payload.subscription.dataWindowStart = params.startDate;
            payload.subscription.dataWindowEnd = params.endDate;
        }

        return payload;
    }
}
